import React, { useEffect } from "react";
import Footer from "../components/Footer.js";
import NavbarInner from "../components/Navbar-Inner.js";

import { useDispatch } from "react-redux";
//components
import InvestDash from "../components/Dashboard/investDashboard.js";
import RoiChart from "../components/Dashboard/RoiChart.js"
import InvestmentDetail from "../components/Dashboard/InvestmentDetail.js"
import RecentActivity from "../components/Dashboard/RecentActivity.js"
import { getStackingData } from "../api/invest.js";
import { useTranslation } from "react-i18next";
// import { getUserSettings } from "../api/walletAction.js";


// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Dashboard() {
  const dispatch = useDispatch();
  const {t} = useTranslation()
  //Stake data for redux reference
  const Investments = async () => {
    try {
      await getStackingData(dispatch);
      // await getUserSettings(dispatch);
    } catch (err) { console.log("Err on Investments1", err) }
  }

  // const AllOrderhistory = async () => {
  //   try {
  //     const { status, result } = await GetAllOrderhistory();
  //     if (status == 'success') setOrderhistory(result)
  //   } catch (err) { console.log("Err on GetAllOrderhistory", err) }
  // }

  // const fetchData = async () => {
  //   let { status, result } = await getServerTime();
  //   if (status == 'success') setCurDate(result)
  // }

  useEffect(() => {
    Investments()
  }, [])

  return (
    <div>
      <ScrollToTopOnMount />
      <NavbarInner />
      <div className="inner_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="inner_head_title">{t("DASHBOARD")}</h2>
            </div>
          </div>
          <InvestDash />
          <RoiChart />
          <InvestmentDetail />
          <RecentActivity />
        </div>
      </div>
      <Footer />
    </div>
  );
}
