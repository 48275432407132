// import React from "react";
// import ReactApexChart from "react-apexcharts";
// import { substring } from "../../lib/stringCase";

// export default function CapitalGrowthChart(props) {
//   const { roiData /* , monthArray, activeData, CurDate */ } = props;
//   // let isData = activeData && activeData.length > 0;
//   // let CurMonth = new Date(CurDate).getMonth();
//   // let min = Math.min(...monthArray) === Infinity ? null : Math.min(...monthArray);
//   // console.log(roiData, "898098098909807555")
//   let original = roiData && roiData.capital;

//   console.log(original, "ORIGINAL_DATA");
//   const series = [
//     {
//       name: "Capital Growth",
//       data: [100, 150],
//     },
//     {
//       name: "Invest",
//       data: [50, 80],
//     },
//   ];

//   const options = {
//     chart: {
//       foreColor: "#58585f",
//       toolbar: {
//         show: false,
//       },
//       zoom: {
//         enabled: false,
//       },
//       dropShadow: {
//         enabled: false,
//       },
//     },
//     xaxis: {
//       // categories: original.label,
//       categories: [
//         "Jul 2023",
//         "Aug 2023",
//         // "Sep 2023",
//         // "Oct 2023",
//         // "Nov 2023",
//         // "DEC 2023",
//       ],
//       labels: {
//         style: {
//           colors: "#fff",
//           fontSize: "14px",
//           fontFamily: "Craft Gothic",
//           fontWeight: 400,
//           cssClass: "apexcharts-xaxis-label",
//         },
//       },
//       title: {
//         text: "Monthly",
//         style: {
//           color: "#23B2F4",
//           fontSize: "18px",
//           fontFamily: "Craft Gothic",
//           fontWeight: 400,
//           cssClass: "apexcharts-xaxis-title",
//         },
//         margin: 10,
//       },
//     },
//     yaxis: {
//       labels: {
//         style: {
//           colors: "#fff",
//           fontSize: "14px",
//           fontFamily: "Craft Gothic",
//           fontWeight: 400,
//           cssClass: "apexcharts-yaxis-label",
//         },
//       },
//       title: {
//         text: "USD",
//         offsetX: -8,
//         style: {
//           color: "#23B2F4",
//           fontSize: "18px",
//           fontFamily: "Craft Gothic",
//           fontWeight: 400,
//           cssClass: "apexcharts-xaxis-title",
//         },
//       },
//     },
//     grid: {
//       borderColor: "rgba(217, 217, 217, 0.18)",
//     },
//     dataLabels: {
//       enabled: false,
//     },
//     stroke: {
//       curve: "straight",
//       width: 3,
//     },
//     toolbar: {
//       show: false,
//     },
//   };

//   return (
//     <>
//       <ReactApexChart
//         options={options}
//         series={series}
//         type="bar"
//         height={350}
//         className="area_chart"
//       />
//     </>
//   );
// }

import React from "react";
import ReactApexChart from "react-apexcharts";

export default function CapitalGrowthChart(props) {
  const { roiData } = props;
  const series = [
    {
      name: "Invest",
      data: roiData?.capital?.investData || []
    },
    {
      name: "Capital Growth",
      data: roiData?.capital?.data || [],
    },
  ];

  const options = {
    chart: {
      foreColor: "#fff",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      dropShadow: {
        enabled: false,
      },
    },
    xaxis: {
      categories: roiData?.capital?.label || [],
      // categories: ["Jul 2023", "Aug 2023"],
      labels: {
        style: {
          colors: "#fff",
          fontSize: "14px",
          fontFamily: "Craft Gothic",
          fontWeight: 400,
          cssClass: "apexcharts-xaxis-label",
        },
      },
      title: {
        text: "Monthly",
        style: {
          color: "#23B2F4",
          fontSize: "18px",
          fontFamily: "Craft Gothic",
          fontWeight: 400,
          cssClass: "apexcharts-xaxis-title",
        },
        margin: 10,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#fff",
          fontSize: "14px",
          fontFamily: "Craft Gothic",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-label",
        },
      },
      title: {
        text: "USD",
        offsetX: -8,
        style: {
          color: "#23B2F4",
          fontSize: "18px",
          fontFamily: "Craft Gothic",
          fontWeight: 400,
          cssClass: "apexcharts-xaxis-title",
        },
      },
    },
    grid: {
      borderColor: "rgba(217, 217, 217, 0.18)",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 3,
    },
    toolbar: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "80%",
        endingShape: "flat",
        stacked: true,
      },
    },
  };

  return (
    <>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
        className="area_chart"
      />
    </>
  );
}
