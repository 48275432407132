import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

import ROIPercentChart from "./ROIPercentChart.js";
import CapitalGrowthChart from "./CapitalGrowthChart.js";
import StrategyChart from "./StrategyChart.js";

// import lib
import isEmpty from "../../lib/isEmpty.js";
// import { fourMonthsBefore, generateMonthYearArray } from "../../lib/datemanipulation.js";
import {
  getDashboardChart,
  getLatestInvestChart,
  getStrategyData,
} from "../../api/invest.js";
import { getPlanSettings, getUserPlanSettings } from "../../api/dashboard.js";
import { findPerc, precentConvetPrice } from "../../lib/calculation.js";
import { roundToNearestPointFive } from "../../lib/roundOf.js";
import { useTranslation } from "react-i18next";
// import { fillMissingMonths } from "../../lib/dateTimeHelper.js";

export default function Chart() {
  const [activeChart, setActiveChart] = useState("capital_growth");
  const { stackingData } = useSelector((state) => state);
  const [stakingData, setStakingData] = useState({
    strategies: [],
    series: [],
  });
  const [roiData, setRoiData] = useState({
    capital: { label: [], data: [], investData: [] },
    interest: { label: [], data: [] },
  });
  const [selectId, setSelectId] = useState("");
  const [Data, setData] = useState({});

  const { t } = useTranslation();

  const handleActiveChartClick = (button) => {
    setActiveChart(button);
  };

  const handleActiveStrategy = async (e, activeChart) => {
    try {
      e.preventDefault();
      let { value } = e.target;
      setSelectId(value);
      let chartdatum = {
        capital: { label: [], data: [], investData: [] },
        interest: { label: [], data: [] },
      };

      if (value) {
        let findData =
          Data?.result &&
          Data?.result.length > 0 &&
          Data?.result.filter(
            (el) => el.stakeId?.toString() == value?.toString()
          );
        if (findData) {
          //capital
          let month = [];
          let amount = [];
          let investAmount = [];
          for (let i in findData) {
            let d = moment(
              findData[i]._id.year + "/" + findData[i]._id.month
            ).format("MMM YYYY");
            month.push(d);
            amount.push(findData[i].capitalGrowth);
            investAmount.push(findData[i].amount);
          }
          chartdatum.capital = {
            label: month,
            data: amount,
            investData: investAmount,
          };
        }
        let foundData =
          Data?.result2 &&
          Data?.result2.length > 0 &&
          Data?.result2.filter(
            (el) => el.stakeId.toString() == value.toString()
          );
        if (foundData) {
          //interest
          let month = [];
          let amount = [];
          for (let i in foundData) {
            let d = moment(
              foundData[i]._id.year + "/" + foundData[i]._id.month
            ).format("MMM YYYY");
            month.push(d);
            amount.push(foundData[i].amount);
          }
          chartdatum.interest = { label: month, data: amount };
        }
      }
      setRoiData(chartdatum);
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const StrategyDataFunc = async (PlanData) => {
    try {
      const { status, result } = await getStrategyData();
      if (status == "success") {
        // setStrategyData(result)
        let totalSum = 0,
          btcmining = 0,
          btc = 0,
          eth = 0,
          ms = 0;
        if (result && result.length > 0) {
          totalSum = result.reduce((acc, cur) => {
            let findPlan = PlanData.find(
              (el) => el._id.toString() === cur._id.toString()
            );
            findPlan = findPlan.planSetting;
            if (findPlan) {
              btcmining += precentConvetPrice(
                cur.amount,
                findPlan?.btcmining ? findPlan?.btcmining : 0
              );
              btc += precentConvetPrice(cur.amount, findPlan?.btc);
              eth += precentConvetPrice(cur.amount, findPlan?.eth);
              ms += precentConvetPrice(cur.amount, findPlan?.mantisStrategies);
            }

            return (acc = acc + cur.amount);
          }, 0);

          btcmining = findPerc(btcmining, totalSum);
          btc = findPerc(btc, totalSum);
          eth = findPerc(eth, totalSum);
          ms = findPerc(ms, totalSum);

          let strategies = ["BTC Mining", "BTC", "ETH", "Mantis Strategies"];
          let series = [
            roundToNearestPointFive(btcmining),
            roundToNearestPointFive(btc),
            roundToNearestPointFive(eth),
            roundToNearestPointFive(ms),
          ];

          // setChartData({})
          setStakingData((prev) => ({
            ...prev,
            strategies,
            series,
          }));
        }
      }
    } catch (err) {
      console.log("Err on GetAllOrderhistory", err);
    }
  };
  // const fetch
  const fetchUserPlanSetting = async () => {
    const { status, result } = await getUserPlanSettings();
    if (status == "success") {
      return result;
    }
    return {};
  };

  const fetchData = async () => {
    let { status, result } = await getPlanSettings();
    const customSolution = await fetchUserPlanSetting();

    if (status == "success") {
      const index = result?.findIndex(
        (item) => item.strategy == "Custom Solution"
      );
      result[index] = customSolution;
      console.log("RESULT", result);
      // setPlanData(result)
      StrategyDataFunc(result);
    }
  };
  // const setROIChartData = () => {
  //   if (!isEmpty(Orderhistory)) {
  //     setOrderData(Orderhistory)
  //     let filterData = Orderhistory && Orderhistory[0];

  //     let firstData = { period: filterData.period, id: filterData.stakeId, monthlist: filterData.months };

  //     let AllOrderData = Orderhistory && Orderhistory.filter((el) => el.stakeId.toString() == firstData.id.toString())
  //     let usrdata = userSettings && userSettings?.stakeSetting;
  //     if (!isEmpty(usrdata)) {
  //       let findData = usrdata && usrdata.length > 0 && usrdata.find((el) => el.stakeId.toString() == firstData.id.toString())
  //       let isData = findData.monthlist.every(el => el.previous == 0);
  //       if (!isData) {
  //         firstData.monthlist = findData.monthlist;
  //       }
  //     }

  //     setRoiData(firstData.monthlist)
  //     setActiveData(AllOrderData)
  //   } else {
  //     let firtData = userSettings && userSettings?.stakeSetting;
  //     if (firtData && firtData[0]) {
  //       let single = firtData[0];

  //       setRoiData(single.monthlist)
  //       setActiveData([])
  //     }
  //   }
  // }

  // const NewSetROIChartData = (Orderhistory, CurDate, activeChart) => {
  //   if (Orderhistory && Orderhistory.length > 0 && CurDate) {
  //     setOrderData(Orderhistory)
  //     let filterData = Orderhistory && Orderhistory[0];

  //     let firstData = { period: filterData.period, id: filterData.stakeId, monthlist: filterData.months };
  //     let usrdata = userSettings && userSettings?.stakeSetting;
  //     setSelectId(firstData.id)
  //     if (!isEmpty(usrdata)) {
  //       let findData = usrdata && usrdata.length > 0 && usrdata.find((el) => el.stakeId.toString() == firstData.id.toString())
  //       if (activeChart == 'roi_percent') {
  //         let isData = findData.monthlist.every(el => el.APY == null);
  //         if (!isData) {
  //           firstData.monthlist = findData.monthlist;
  //         }
  //       } else {
  //         let isData = findData.monthlist.every(el => el.capGrowth == null);
  //         if (!isData) {
  //           firstData.monthlist = findData.monthlist;
  //         }
  //       }
  //     }

  //     //calc candle monthlist
  //     let check = true
  //     let array = [];
  //     Orderhistory && Orderhistory.length > 0 && Orderhistory.filter((item) => {
  //       array.push(new Date(item.createdAt))
  //       return arr.push(new Date(item.createdAt).getMonth())
  //     })

  //     if (array && array.length > 0) {
  //       let last = array[array.length - 1];
  //       const monthYearArray = generateMonthYearArray(new Date(last), firstData.monthlist, new Date(CurDate), check);
  //       setRoiData(monthYearArray)
  //     }
  //   } else {
  //     let firtData = userSettings && userSettings?.stakeSetting;
  //     if (firtData && firtData[0]) {
  //       let single = firtData[0];
  //       setSelectId(single.id)
  //       //calc candle monthlist
  //       let start = new Date()
  //       let resultDate = fourMonthsBefore(start);

  //       let check = false
  //       const monthYearArray = generateMonthYearArray(new Date(resultDate), single.monthlist, start, check);
  //       setRoiData(monthYearArray)
  //     }
  //   }
  // }

  // const setData = () => {
  //   let strategies = []
  //   let series = []
  //   let chartDetails = []
  //   for (let i = 0; i < stackingData.length; i++) {
  //     strategies.push(stackingData[i].strategy)
  //     series.push(stackingData[i].chartPercent)
  //     chartDetails.push({
  //       strategy: stackingData[i].strategy,
  //       percent: stackingData[i].chartPercent,
  //     });
  //   }
  //   setStakingData((prev) => ({
  //     ...prev,
  //     strategies,
  //     series,
  //   }));
  //   setChartData(chartDetails)
  // }

  // useEffect(() => {
  //   setData()
  // }, [])

  // let arr = [];
  // activeData && activeData.length > 0 && activeData.filter((item) => {
  //   return arr.push(new Date(item.createdAt).getMonth())
  // })

  // useEffect(() => {
  //   if (Orderhistory && CurDate)
  //     NewSetROIChartData(Orderhistory, CurDate, activeChart)
  // }, [Orderhistory])

  const NewSetChartData = async (chartdata) => {
    let value =
      chartdata.result && chartdata.result[0]?.stakeId
        ? chartdata.result[0]?.stakeId
        : stackingData[0]._id;
    setSelectId(value);
    let chartdatum = { ...roiData };

    if (value) {
      let findData =
        chartdata?.result &&
        chartdata?.result.length > 0 &&
        chartdata?.result.filter(
          (el) => el.stakeId?.toString() == value?.toString()
        );
      if (findData) {
        //capital
        let month = [];
        let amount = [];
        let investAmount = [];
        for (let i in findData) {
          let d = moment(
            findData[i]._id.year + "/" + findData[i]._id.month
          ).format("MMM YYYY");
          month.push(d);
          amount.push(findData[i].capitalGrowth);
          investAmount.push(findData[i].amount);
        }
        chartdatum = {
          ...roiData,
          ...{
            capital: { label: month, data: amount, investData: investAmount },
          },
        };
      }

      let foundData =
        chartdata?.result2 &&
        chartdata?.result2.length > 0 &&
        chartdata?.result2.filter(
          (el) => el.stakeId.toString() == value.toString()
        );
      if (foundData) {
        //interest
        let month = [];
        let amount = [];
        for (let i in foundData) {
          let d = moment(
            foundData[i]._id.year + "/" + foundData[i]._id.month
          ).format("MMM YYYY");
          month.push(d);
          amount.push(foundData[i].amount);
        }
        chartdatum = {
          ...roiData,
          ...{ interest: { label: month, data: amount } },
        };
      }
    }

    setRoiData(chartdatum);
  };

  const fetchChartData = async () => {
    let { status, result } = await getDashboardChart();
    if (status == "success") {
      setData(result);
      NewSetChartData(result);
    }
  };
  const fetchLatestChartData = async () => {
    let { status, result } = await getLatestInvestChart();
    if (status == "success") {
      setData(result);
      NewSetChartData(result);
    }
  };
  useEffect(() => {
    // fetchChartData();
    fetchLatestChartData();
    fetchData();
  }, []);

  return (
    <div>
      <div className="row dash_box_row">
        <div className="col-xl-7">
          <div className="chart_box">
            <div className="active_chart_btngrp">
              <button
                className={activeChart === "capital_growth" ? "active" : ""}
                onClick={() => handleActiveChartClick("capital_growth")}
              >
                {t("INVEST_DASHBOARD_CAPITAL_GROWTH")}
              </button>{" "}
              <button
                className={activeChart === "roi_percent" ? "active" : ""}
                onClick={() => handleActiveChartClick("roi_percent")}
              >
                {t("INVEST_DASHBOARD_ROI_PERCENT")}
              </button>
            </div>

            <div
              className={activeChart === "roi_percent" ? "d-block" : "d-none"}
            >
              <div className="inner_subtitle title_flex">
                <div className="earning_btngrp">
                  {/* <select
                    className="form-select chart_option_btn"
                    value={selectId}
                    onChange={(e) => handleActiveStrategy(e, "roi_percent")}
                  ></select> */}
                  {stackingData &&
                    stackingData.length > 0 &&
                    stackingData.map((option) => (
                      <button
                        onClick={(e) => handleActiveStrategy(e, "roi_percent")}
                        className={`form-select chart_option_btn ${
                          selectId == option._id
                            ? "chart_option_btn active"
                            : ""
                        }`}
                        key={option.value}
                        // hidden={selectId == option._id}
                        value={option._id}
                      >
                        {option.strategy == "Balanced"
                          ? t("BALANCED_TITLE")
                          : option.strategy == "High Yield"
                          ? t("HIGH_YIELD_TITLE")
                          : option.strategy == "Custom Solution"
                          ? t("CUSTOM_SOLUTION")
                          : t("GROWTH_TITLE")}
                      </button>
                    ))}
                </div>
              </div>
              <ROIPercentChart roiData={roiData} />
            </div>

            <div
              className={
                activeChart === "capital_growth" ? "d-block" : "d-none"
              }
            >
              <div className="inner_subtitle title_flex">
                <div className="earning_btngrp">
                  {/* <select
                    className="form-select chart_option_btn"
                    value={selectId}
                    onChange={(e) => handleActiveStrategy(e, "capital_growth")}
                  >
                    {stackingData &&
                      stackingData.length > 0 &&
                      stackingData.map((option) => (
                        <option key={option.value} value={option._id}>
                          {option.strategy}
                        </option>
                      ))}
                  </select> */}
                  {stackingData &&
                    stackingData.length > 0 &&
                    stackingData.map((option) => (
                      <button
                        className={`form-select chart_option_btn ${
                          selectId == option._id
                            ? "chart_option_btn active"
                            : ""
                        }`}
                        onClick={(e) =>
                          handleActiveStrategy(e, "capital_growth")
                        }
                        key={option.value}
                        value={option._id}
                      >
                        {option.strategy == "Balanced"
                          ? t("BALANCED_TITLE")
                          : option.strategy == "High Yield"
                          ? t("HIGH_YIELD_TITLE")
                          : option.strategy == "Custom Solution"
                          ? t("CUSTOM_SOLUTION")
                          : t("GROWTH_TITLE")}
                      </button>
                    ))}
                </div>
              </div>
              <CapitalGrowthChart roiData={roiData} />
            </div>
          </div>
        </div>

        <div className="col-xl-5">
          <div className="chart_box">
            <StrategyChart data={stakingData} />
            <div class="roi_calc_value stategy_calc_value">
              {!isEmpty(stakingData) &&
                stakingData?.strategies.length > 0 &&
                stakingData?.strategies.map((item, key) => (
                  <div>
                    <label className="text-light">{item}:</label>
                    <label>{stakingData?.series[key]} %</label>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
